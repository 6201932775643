<template>
  <v-container
    id="start"
    fill-height
    class="d-flex flex-column justify-center"
  >
    <v-scroll-y-transition>
      <signed-feedback
        v-if="routeSigned"
      />
      <login
        v-else-if="((installed||pwa)||passwordRecovery||routeTos||routePrivacy||routeCancel)||dev"
        :installed="installed"
        :pwa="pwa"
        :show="show"
        :modal="modal"
        :password-recovery="passwordRecovery"
        :route-tos="routeTos"
        :route-privacy="routePrivacy"
        :route-cancel="routeCancel"
      />
      <install
        v-else
        :installed="installed"
        :pwa="pwa"
      />
    </v-scroll-y-transition>

    <toast />
  </v-container>
</template>

<style>

  #start {
    padding: 0;
    width: 100%;
    /* background: var(--driver-bg) !important; */
  }
  @media (min-width: 600px) and (orientation: landscape) {
    #start {
      width: 360px;
      max-height: 640px;
      margin: 4rem auto;
      border-radius: 1rem;
      border: 1px solid var(--v-secondary-darken3);
    }
  }


</style>

<script>

  // Icons
  import {
    mdiHelpCircleOutline,
    mdiEye,
    mdiEyeOff,
    mdiClose
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { get, sync } from 'vuex-pathify'
  import device from 'mobile-device-detect';


  export default {
    name: 'Start',

    props: {
      installed: {
        type: Boolean,
        default: false
      },
      modal: {
        type: String,
        default: null
      },
      dev: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        mdiHelpCircleOutline,
        mdiEye,
        mdiEyeOff,
        mdiClose,
      },
      ios: device.isIOS,
      show: false,
    }),

    computed: {
      // views: sync('login/views'),
      // help: sync('app/views@help'),
      // help_sections: sync('help/sections'),
      status: get('user/status'),
      auth: get('user/auth'),
      toast: sync('app/toast'),

      pwa () {
        return device.isIOS && navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
      },

      passwordRecovery () {
        const a = this.getRecoveryToken() !== null ? true : false;
        const b = this.getRecoveryCPF() !== null ? true : false;
        return a&&b ? true : false;
      },

      routeTos () {
        // check route to TOS direct
        return this.$route.path == '/tos' ? true : false;
      },

      routePrivacy () {
        // check route to TOS direct
        return this.$route.path == '/privacy' ? true : false;
      },

      routeCancel () {
        // check route to cancel account direct
        return this.$route.path == '/cancel' ? true : false;
      },

      routeSigned () {
        // check route to cancel account direct
        return this.$route.path == '/termo-assinado' ? true : false;
      },
    },

    methods: {
      ...services,

      getRecoveryToken () {
        // get recovery token if present
        return typeof this.$route.params.resetToken!=='undefined' ? this.$route.params.resetToken : null
      },
      getRecoveryCPF () {
        // get recovery CPF if present
        return typeof this.$route.params.cpf!=='undefined' ? this.$route.params.cpf : null
      },

      checkNative () {
        this.toggleToast(
          true,
          'mobees: '+ typeof mobees,
          1000
        );
        console.log('mobees: '+ typeof mobees);
        if (typeof mobees == 'undefined') {
          setTimeout(this.checkNative, 1000);
        }
      }
    },

    mounted () {
      this.show = true;
      this.cancelToasts();
    },

    components: {
      Login: () => import('@/views/login/Login'),
      Install: () => import('@/views/login/Install'),
      SignedFeedback: () => import('@/components/SignedFeedback'),
      Toast: () => import('@/components/Toast'),
    },

  }
</script>
